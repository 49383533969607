import { Runtime } from '@wqd/designer-core-lib'
import * as PublishRuntime from '@wqd/designer-core-lib/dist/runtime/PublishRuntime'

function trigger(data: any) {
    window.bridge(data)

}
function checkLogin(go: boolean) {
    let token = localStorage.getItem('wqd-member-token');
    if (!token || token === '') {
        if (go === true) {
            window.open('/member/#/', '_self')
        }
    }
    return false;
}
export default function (runtime: PublishRuntime.PublishRuntime) {

    runtime.bridge.buyVipCard = async function (cardId: string) {
        trigger({
            name: 'VIP_CARD_DETAIL',
            id: cardId
        })
        return true;
    }
    runtime.bridge.isInBox = function () {
        return window.parent && window.parent !== window;
    }
}
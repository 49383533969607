export namespace ScrollMonitor {
    export interface IMonitor {
        isShown?: boolean,
        el: HTMLElement,
        rectEl?: HTMLElement
    }
    export function Check(wrapper: IMonitor, showfn: Function, hidefn: Function) {
        let b=  false;
       
        let rect = getPageRect();
        if (wrapper.rectEl) {
            let trect = getElementRect(wrapper.rectEl);
            let orect = rect;
            rect = [orect[0] > trect[0] ? orect[0] : trect[0],
            orect[1] > trect[1] ? orect[1] : trect[1],
            orect[2] < trect[2] ? orect[2] : trect[2],
            orect[3] < trect[3] ? orect[3] : trect[3]]
        }
        let erect = getElementRect(wrapper.el);
        let r = rectsIntersect(rect, erect);



        if (wrapper.isShown === undefined) {
            if (r === true) {
                showfn()
            }
        }
        else {
            if (r !== wrapper.isShown) {
                r ? showfn() : hidefn()
            }
        }
        wrapper.isShown = r;

    }

    function getPageRect(): [number, number, number, number] {
        var isquirks = document.compatMode !== 'BackCompat';
        var page = isquirks ? document.documentElement : document.body;
        var x = page.scrollLeft;
        var y = page.scrollTop;
        var w = 'innerWidth' in window ? window.innerWidth : page.clientWidth;
        var h = 'innerHeight' in window ? window.innerHeight : page.clientHeight;
        return [x, y, x + w, y + h];
    }

    function getElementRect(element: HTMLElement): [number, number, number, number] {
        // var x = 0, y = 0;
        // var w = element.offsetWidth, h = element.offsetHeight;
        // while (element.offsetParent !== null) {
        //     x += element.offsetLeft;
        //     y += element.offsetTop;
        //     element = element.offsetParent as HTMLElement;
        // }
        // return [x, y, x + w, y + h];
        var isquirks = document.compatMode !== 'BackCompat';
        var page = isquirks ? document.documentElement : document.body;
        var x = page.scrollLeft;
        var y = page.scrollTop;
        let r = element.getBoundingClientRect();
        
        return [r.left+x, r.top+y, r.left+x + r.width, r.top+y + r.height];
    }

    function rectsIntersect(a: [number, number, number, number], b: [number, number, number, number]) {
        return a[0] < b[2] && a[2] > b[0] && a[1] < b[3] && a[3] > b[1];
    }


}
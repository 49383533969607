/**
 * DO NOT IMPORT ANY OTHER FILES FROM @designer-core TO KEEP THE BUNDLE SLIM
 */
import { Config } from '@wqd/designer-core-lib/dist/runtime/Config'
import * as PublishRuntime from '@wqd/designer-core-lib/dist/runtime/PublishRuntime'
import * as PageType from '@wqd/designer-core-lib/dist/modal/PageType'
import requirejs from './requirejs.wrapper'
import * as AnimationDefine from '@wqd/designer-core-lib/dist/define/Animation'
import * as ScrollMonitor from './scrollMonitor'
import { Responsive } from '@wqd/designer-core-lib/dist/define/Responsive'
import Bridge from './bridge'
import * as Events from './events'

// import { Runtime } from '@designer-core/runtime';


let headroomEl = document.querySelector('[app-id=ID_COMMON_NAVIGATOR]') as HTMLElement;
if (headroomEl) {

    let items = headroomEl.querySelectorAll('li.item[high-light-page-id]');
    for (let i = 0; i < items.length; i++) {
        let item = items.item(i);
        if (item.getAttribute('high-light-page-id') == (window as any).pageId) {
            item.classList.add('high-light-page-item');
        }
    }
    headroomEl.setAttribute('app-type', 'comp');
    // headroomEl.addEventListener('scroll',function(e){
    //     e.stopPropagation();
    //     e.preventDefault();
    // })
    headroomEl.querySelector('[app-id=off-comp-nav-ul]').addEventListener('scroll', function (e) {
        console.log('a')
        e.stopPropagation();
        e.preventDefault();
    }, {
        capture: true
    })
    // headroomEl.style.visibility = 'visible'
}

let config = Array.isArray(Config.config) ? Config.config[0] : (Config.config || Config.headerConfig);
if ((window as any).pageType) {
    config.pageType = (window as any).pageType
}

if (Array.isArray(config)) {
    config = config[0]
}
if (!config) {
    throw new Error('No wqd config founded.');
}

if (config.environment !== 'publish') {
    throw new Error('Environment should be "publish"')
}


let gCheckEntranceAnimes: Function = () => { };




requirejs.config({
    paths: {
        [Config.RootType.CDN]: Config.cdnURL,
        [Config.RootType.LOCAL]: '/deps'
    }
});

const buildRuntime = (baseRuntime: PublishRuntime.PublishRuntime, comp: Config.IComponent | string) => {
    let runtime = new PublishRuntime.PublishRuntime(baseRuntime.libs, baseRuntime.environment, baseRuntime.fullpage);
    runtime.checkEntranceAnimes = () => { gCheckEntranceAnimes() }
    runtime.getLang = () => { return config.lang }
    runtime.getSiteId = () => { return config.siteId }
    runtime.order = baseRuntime.order;
    runtime.bridge = baseRuntime.bridge;
    // if(comp.id==='ID_cf502de0-bf62-11ea-9f7a-ab4dc78032e5'){
    //     console.log(comp)
    // }

    if (typeof comp === 'string') {
        comp = config.comps[comp];
    }
    if (!comp) {
        return null;
    }
    if (!comp._wrappedMethods) {
        comp._wrappedMethods = {};
        for (let k in comp.methods!) {
            comp._wrappedMethods[k] = function (...args: any) {
                let c = (comp as Config.IComponent);
                return c.methods![k].apply(runtime, args);
            }
        }
    }

    runtime.methods = comp._wrappedMethods;

    runtime.properties = comp.properties;
    runtime.buildRuntime = (comp: Config.IComponent | string) => buildRuntime(baseRuntime, comp);

    return runtime;
}
const showAnimation = (el: HTMLElement, className: string, durationInSecond: number, delayInSecond: number, isInfinite: boolean, iterationCount: number, type: AnimationDefine.Animation.ActionType) => {
    let cleanerFunc = function () {
        el.style.animationDelay = '';
        el.style.webkitAnimationDelay = '';
        el.style.animationDuration = '';
        el.style.webkitAnimationDuration = '';
        el.style.animationIterationCount = '';
        el.style.webkitAnimationIterationCount = '';
        el.classList.remove(className, 'animated');

        if (type === AnimationDefine.Animation.ActionType.EXIT) {
            el.classList.add('animeHide')
        }
        el.removeEventListener('animationend', animatedEvent);
    }
    let animatedEvent = function () {

        cleanerFunc();
    };
    if (type === AnimationDefine.Animation.ActionType.ENTRANCE) {
        el.classList.remove('animeHide')
    }
    el.addEventListener('animationend', animatedEvent);
    let delay = `${delayInSecond}s`;
    el.style.animationDelay = delay;
    el.style.webkitAnimationDelay = delay;
    let duration = `${durationInSecond}s`;
    el.style.animationDuration = duration;
    el.style.webkitAnimationDuration = duration;
    let iteration = isInfinite ? 'infinite' : iterationCount + '';
    el.style.animationIterationCount = iteration;
    el.style.webkitAnimationIterationCount = iteration;
    el.classList.add(className, 'animated');
    return cleanerFunc;

}
const callHook = (hookName: string, runtime: PublishRuntime.PublishRuntime, comp: Config.IComponent) => {
    runtime = buildRuntime(runtime, comp);
    if (comp.hooks![hookName]) {
        let el = document.getElementById(comp.id);
        if (!el) {
            return;
        }
        comp.hooks![hookName].apply(runtime, [el]);
    }
}

let deps = config.deps;

let animeCleanerFuncs: any = {}

let jqueryDep = Config.InternalDependencies[Config.InternalDependency.JQUERY]
requirejs.config({
    waitSeconds: 0,
    map: {
        '*': {
            'jquery': `${jqueryDep.rootType}/${jqueryDep.module}`
        }
    }
})
deps.unshift(Config.InternalDependencies[Config.InternalDependency.JQUERY]);

let jsDeps = deps.filter(dep => dep.module !== undefined);
// jsDeps.push(Config.InternalDependencies[Config.InternalDependency.TMAP]);


requirejs(jsDeps.map(dep => {
    return `${dep.rootType}/${dep.module}`
}), function () {

    let libs: { [index: string]: any } = {};
    let arr: any[] = []
    for (let i = 0; i < arguments.length; i++) {
        arr.push(arguments[i])
    }

    Promise.all(arr).then((args) => {
        jsDeps.forEach((dep, i) => {

            libs[dep.name] = args[i];
            if (dep.globalName) {
                libs[dep.name] = (window as any)[dep.globalName];
            }
        });
        let domReady = libs[Config.InternalDependency.__DOM_READY];
        delete libs[Config.InternalDependency.__DOM_READY];

        // libs['plyr'] = (window as any).Plyr
   

        domReady(() => {

            if (['h5', 'htmlFH'].includes(config.pageType)) {

                let navEl = document.querySelector('[app-id=ID_COMMON_NAVIGATOR]');
                //sdf
                if (navEl) {
                    document.body.prepend(navEl)
                }

            }


            let entranceAnimesPromiseRes: Function = () => { }
            let entranceAnimesPromise = new Promise((res) => {
                entranceAnimesPromiseRes = res;
            })


            if (PageType.PageType.ResponsiveTypes.includes(config.pageType) || config.pageType === PageType.PageType.Type.MOBILESTATIC) {

                let parallaxEls = document.querySelectorAll('[data-parallax-enabled=true][data-parallax-speed]')
                for (let i = 0; i < parallaxEls.length; i++) {
                    let el = parallaxEls.item(i) as HTMLElement;
                    el.style.backgroundPositionY = `0px`;
                    el.style.backgroundRepeat = 'repeat';
                }
                window.addEventListener('scroll', function () {
                    let y = window.pageYOffset;
                    for (let i = 0; i < parallaxEls.length; i++) {
                        let el = parallaxEls.item(i) as HTMLElement;
                        let speed = parseFloat(el.getAttribute('data-parallax-speed')!);

                        el.style.backgroundPositionY = `${-y * speed}px`;
                    }
                });

                let sections = document.querySelectorAll('div[app-type=section],div[app-id=ID_COMMON_NAVIGATOR]');

                let stickyCount = 0;
                // let navEl = document.querySelector('div[app-id=ID_COMMON_NAVIGATOR]') as HTMLElement
                // let navHeight = navEl.getBoundingClientRect().height;

                if (sections.length > 0) {
                    for (let i = 0; i < sections.length - 1; i++) {
                        let sec = sections.item(i) as HTMLElement;
                        // if (sec.hasAttribute('app-id') && sec.getAttribute('app-id') === 'ID_COMMON_NAVIGATOR') {
                        //     let resp = config.responsive.navigatorPhone === undefined ? Responsive.navigatorResponsiveThreshold : config.responsive.navigatorPhone;
                        //     let menuc = sec.querySelector('nav.menu-c');
                        //     if (menuc) {
                        //         let resizeFix = function () {

                        //             if (window.innerWidth >= resp) {
                        //                 menuc!.classList.remove('menu-phone')
                        //             }
                        //             if (window.innerWidth < resp) {
                        //                 menuc!.classList.add('menu-phone')
                        //             }
                        //         };
                        //         resizeFix();
                        //         window.addEventListener('resize', resizeFix);
                        //         menuc!.classList.add('menu-inited')
                        //     }

                        // }
                        if (sec.hasAttribute('data-sticky')) {
                            if (sec.getAttribute('app-id') !== 'ID_COMMON_NAVIGATOR') {
                                sec.style.zIndex = 100000 + i + '';
                            }



                            let height = sec.getBoundingClientRect().height;
                            let placeholderEle = document.createElement('div');
                            placeholderEle.style.height = `${height}px`
                            placeholderEle.style.display = 'none';
                            sec.insertAdjacentElement('afterend', placeholderEle);
                            let resizeFunc = () => {
                                let rect = sec.getBoundingClientRect();
                                if (sec.style.position === 'fixed') {
                                    sec.style.left = `${(document.body.getBoundingClientRect().width - rect.width) / 2}px`
                                } else {
                                    sec.style.left = '0'
                                }
                            };
                            let scrollFunc = function () {
                                let rect = sec.getBoundingClientRect();
                                let plcRect = placeholderEle.getBoundingClientRect();

                                if (rect.top < 0 && sec.style.position !== 'fixed') {

                                    sec.classList.add('sticky')
                                    sec.style.position = 'fixed';
                                    sec.style.top = '0';

                                    placeholderEle.style.display = 'block'
                                    stickyCount++;

                                }
                                else if (plcRect.top >= 0 && sec.style.position === 'fixed') {

                                    sec.classList.remove('sticky')
                                    sec.style.position = 'relative';
                                    sec.style.top = '0';

                                    placeholderEle.style.display = 'none'
                                    stickyCount--;
                                }
                                resizeFunc()
                            }
                            scrollFunc();
                            window.addEventListener('scroll', scrollFunc)
                            window.addEventListener('resize', resizeFunc)
                        }
                    }
                }

            } else {

            }

            document.querySelectorAll('textarea[comp_form_name]').forEach(ite => ite.innerHTML = '')
            // let rootDiv = document.querySelector('div[app-type=root]')!;
            
            let fixedEls = document.querySelectorAll('.position-fixed');
            fixedEls.forEach(el => {
                // rootDiv.appendChild(el);
                window.document.body.appendChild(el);
            })

            if (headroomEl && !headroomEl.classList.contains('nav-static')) {
                let headroomjs = libs['headroom.js'];
                if (headroomjs) {
                    if (config.pageType !== 'htmlFH') {

                        let q = new libs['headroom.js'](headroomEl);
                        q.init();
                        headroomEl.addEventListener('mouseenter', function () {

                            q.freeze()
                        })
                        headroomEl.addEventListener('mouseleave', function () {
                            q.unfreeze()
                        })
                    } else {
                        let el = headroomEl as HTMLElement;
                        el.style.position = 'absolute'
                    }
                }
            }


            if (config.pageType === 'html') {

                let sections = document.querySelectorAll('div[app-type=root]>div[app-type=section]');
                let fullHeightSections: any[] = [];
                for (let i = 0; i < sections.length; i++) {
                    let sec = sections.item(i);
                    let id = sec.id;
                    let comp = config.comps[id];
                    if (comp && comp.properties.FullHeight && comp.properties.FullHeight.value === true) {
                        fullHeightSections.push(sec)
                    }
                };

                let syncFullHeightSections = () => {
                    console.log('qqq')
                    let h = window.innerHeight
                        || document.documentElement.clientHeight
                        || document.body.clientHeight;
                    fullHeightSections.forEach((el: HTMLElement) => {
                        el.style.height = `${h}px`
                    });

                }
                syncFullHeightSections();
                window.addEventListener(Events.runtime_responsive_changed, syncFullHeightSections);

                entranceAnimesPromise.then((entranceAnimes: any) => {

                    let scrollMonitorFunc = () => {
                        entranceAnimes.forEach((a: any) => {
                            ScrollMonitor.ScrollMonitor.Check(a, () => {
                                a.triggerFunc();
                            }, () => {
                                // a.el.classList.add('animeHide');
                            });
                        })
                    }

                    window.addEventListener('scroll', scrollMonitorFunc);
                    window.addEventListener('resize', scrollMonitorFunc);

                    gCheckEntranceAnimes = scrollMonitorFunc;
                    scrollMonitorFunc()
                })

            }

            let fullpage: any = undefined;

            if (config.pageType === 'h5' || config.pageType === 'htmlFH') {
                let bgVideo = document.querySelectorAll<HTMLElement>('[app-id=COMP_VIDEO_CONTAINER_VIDEO]');
                for (let i = 0; i < bgVideo.length; i++) {
                    bgVideo.item(i).setAttribute('data-keepplaying', 'true')
                }
                let secAppType = 'page';
                if (config.pageType === 'htmlFH') {
                    secAppType = 'section'
                }
                let scrs = document.querySelectorAll(`[app-type=root]>[app-type=${secAppType}]`);
                if (config.pageType === 'h5') {
                    scrs.forEach((scr: any) => {
                        scr.style.width = '100%';
                        scr.style.margin = '0';
                    });
                }
                scrs.forEach(el => {
                    el.setAttribute('data-anchor', `page-${el.id}`)
                })
                let fullpageLib = libs['fullpage.js'];
                if (fullpageLib) {
                    let fpSecs = document.querySelectorAll(`[app-type=${secAppType}]`);
                    let fpEnv: {
                        onChangeCbs: Function[],
                        currentIndex: number,
                        sections: NodeListOf<Element>
                    } = {
                        onChangeCbs: [],
                        currentIndex: 0,
                        sections: fpSecs
                    }

                    fullpage = new fullpageLib('[app-type=root]', {
                        sectionSelector: `[app-type=${secAppType}]`,
                        licenseKey: 'AAAAAAAA-aaaaaaaa-00000000-########',
                        normalScrollElements: '[app-id=ID_COMMON_NAVIGATOR]',
                        verticalCentered: false,
                        animateAnchor: true,
                        lockAnchors: false,

                        onLeave(origin: any, destination: any, direction: any) {
                            fpEnv.currentIndex = destination.index;

                            fpEnv.onChangeCbs.forEach(function (cb: Function) {
                                cb.apply(null, [origin, destination, direction])
                            })
                            // console.log('123',this)
                        },
                        afterLoad(origin: any, destination: any, direction: any) {

                            if (origin === null) {
                                window.history.replaceState(null, '', `#${destination.anchor}`)
                            }

                            entranceAnimesPromise.then((entranceAnimes: any) => {
                                // gCheckEntranceAnimes = function(){
                                //     entranceAnimes.forEach((a: any) => {
                                //         let curr: null | HTMLElement = a.el;
                                //         while (curr) {
                                //             if (curr === destination.item) {
                                //                 a.triggerFunc();
                                //                 break;
                                //             }
                                //             curr = curr.parentElement;
                                //         }
                                //     })
                                // }
                                // gCheckEntranceAnimes(); 
                                let scrollMonitorFunc = () => {
                                    entranceAnimes.forEach((a: any) => {
                                        ScrollMonitor.ScrollMonitor.Check(a, () => {
                                            a.triggerFunc();
                                        }, () => {
                                        });
                                    })
                                }

                                gCheckEntranceAnimes = scrollMonitorFunc;
                                scrollMonitorFunc()
                            })
                        }
                    });
                    fullpage.env = fpEnv;

                    document.querySelector<HTMLElement>('[app-type=root]')!.style.height = scrs.length * 100 + '%';
                    // let ssr_comps = document.querySelectorAll<HTMLElement>('ssr-comp');
                    // ssr_comps.forEach((c: HTMLElement) => {
                    //     c.style.overflowY = 'auto';

                    //     let iframe = c.querySelector('iframe')!;
                    //     iframe.style.height = 'calc(100% + 2px)';
                    //     c.scrollTop = 1;
                    //     let lastTime = 0;//最后一次触发时间戳
                    //     let lastDir = -1;//-1,0,2,最后一次有效滚动方向

                    //     c.addEventListener('scroll', function (e: any) {

                    //         let scrollTop = e.target.scrollTop;
                    //         let now = Date.now();
                    //         let last = lastTime;
                    //         lastTime = now;
                    //         let p = () => {
                    //             if (scrollTop === 0) {
                    //                 fullpage.moveSectionUp();
                    //             }
                    //             else if (scrollTop === 2) {
                    //                 fullpage.moveSectionDown();
                    //             };
                    //             lastDir = scrollTop;
                    //         };
                    //         if (scrollTop === 0 || scrollTop === 2) {
                    //             if (lastDir !== scrollTop || now - last > 200) {//反向滚动或两次触发间隔超过100ms则执行滚动

                    //                 p();
                    //             }
                    //             setTimeout(() => {
                    //                 c.scrollTop = 1;
                    //             }, 0);
                    //         }




                    //     }, { capture: true });


                    // }, true)
                    window.addEventListener('message', function (e: MessageEvent) {
                        let data = e.data;
                        if (typeof data !== 'string') {
                            return;
                        }
                        let d: null | any = null;
                        try {
                            d = JSON.parse(data);
                        } catch (e) {

                        }
                        if (d.type === 'PREVIEW_COMMAND') {
                            if (d.data === 'PREV_PAGE') {
                                fullpage.moveSectionUp();
                            }
                            if (d.data === 'NEXT_PAGE') {
                                fullpage.moveSectionDown();
                            }
                        }
                    });
                }

            }
            //scrollMonitorFunc();

            let Runtime = new PublishRuntime.PublishRuntime(libs, config.environment, fullpage);
            Bridge(Runtime);
            console.log(Runtime);
            (window as any).Runtime = Runtime;

            for (let k in config.comps) {
                let comp = config.comps[k];
                callHook('CREATED', Runtime, comp);
            }


            let animes = config.animes;
            let entranceAnimes: (ScrollMonitor.ScrollMonitor.IMonitor & { anime: AnimationDefine.Animation.IAnimation, triggerFunc: Function })[] = [];

            animes.forEach(anime => {

                if (anime.type !== AnimationDefine.Animation.Type.BUILTIN || !anime.builtin) {
                    return;
                }

                if (typeof anime.target !== 'string' || anime.target.length === 0) {
                    return;
                }

                let targetEls = document.querySelectorAll(`[id=${anime.target}]`) as NodeListOf<HTMLElement>;

                if (targetEls.length === 0) {
                    return;
                }
                if (typeof anime.triggerTarget !== 'string' || anime.triggerTarget.length === 0) {
                    return;
                }

                let triggerTargetEls = document.querySelectorAll(`[id=${anime.triggerTarget}]`);
                if (triggerTargetEls.length === 0) {
                    return;
                }
                let triggerFunc = (el: HTMLElement) => {
                    return () => {
                        if (animeCleanerFuncs[el.id]) {
                            animeCleanerFuncs[el.id]();
                            animeCleanerFuncs[el.id] = undefined;

                        }

                        animeCleanerFuncs[el.id as string] = showAnimation(el,
                            (anime.builtin as any).cssName,
                            anime.durationTime,
                            anime.delayTime,
                            anime.iterationCount === 'infinite',
                            anime.iterationCount as number,
                            anime.action
                        ) as any;
                    }
                }

                if (anime.event === AnimationDefine.Animation.EventType.CLICK) {
                    // (function(anime:AnimationDefine.Animation.IAnimation){

                    triggerTargetEls.forEach((el) => {
                        targetEls.forEach((tarEl) => {
                            el.addEventListener('click', triggerFunc(tarEl));
                        })

                    })
                    // })(anime);   
                }
                if (anime.event === AnimationDefine.Animation.EventType.MOUSEENTER) {
                    triggerTargetEls.forEach((el) => {
                        targetEls.forEach((tarEl) => {
                            el.addEventListener('mouseenter', triggerFunc(tarEl));
                        })

                    });
                }
                if (anime.event === AnimationDefine.Animation.EventType.MOUSELEAVE) {
                    triggerTargetEls.forEach((el) => {
                        targetEls.forEach((tarEl) => {
                            el.addEventListener('mouseleave', triggerFunc(tarEl));
                        })

                    })

                }
                if (anime.event === AnimationDefine.Animation.EventType.ENTRANCE) {

                    targetEls.forEach(el => {
                        triggerTargetEls.forEach((triEl) => {
                            entranceAnimes.push({
                                el: el,
                                anime: anime,
                                triggerFunc: triggerFunc(el),
                                rectEl: anime.target === anime.triggerTarget ? undefined : triEl as HTMLElement
                            })
                        })

                    })
                }
            });

            entranceAnimesPromiseRes(entranceAnimes)


            let fontIcons = document.querySelectorAll<HTMLElement>(`.fa[app-type="comp"],
                [class^="simplelineicons-"][app-type="comp"],
                [class^="ti-"][app-type="comp"]`)
            function syncFontIconsHeight() {
                ([].slice.call(fontIcons) as Array<HTMLElement>).forEach(ite => {
                    ite.style.visibility = 'visible';

                    // let rect = ite.getBoundingClientRect();
                    ite.style.fontSize = window.getComputedStyle(ite).height
                })
            }
            syncFontIconsHeight()
            window.addEventListener('resize', syncFontIconsHeight)

        })
    });

    let onhashchange = function () {
        Logger.debug('hash changed')
        let hash = location.hash;
        if (hash && hash.length > 0) {
            hash = decodeURI(hash)
            hash = hash.substr(1);
            let items = hash.split('/');
            items.forEach(item => {
                if (item.startsWith('jt=')) {
                    let tag = item.substr(3);
                    let el = document.querySelector(`[app-tag=${tag}]`);
                    if (el) {
                        if (el.hasAttribute('app-type') && el.getAttribute('app-type') === 'section' && el.hasAttribute('data-anchor')) {

                            location.href = `#${el.getAttribute('data-anchor')}`
                        } else {
                            location.href = `#${el.id}`
                        }
                    }

                }
            })
        }

    }
    onhashchange();
    window.onhashchange = onhashchange;



})





